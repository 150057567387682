// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Popup.css */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.popup_inner {
    margin: 15px;
}

.popup h1 {
    font-size: 20px;
    margin-bottom: 20px;
}

.popup ul {
    list-style: none;
    padding: 0;
}

.popup ul li {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #f9f9f9;
}

.popup ul li:hover {
    background-color: #efefef;
}

.popup ul li.selected {
    background-color: #007bff;
    color: white;
}


.popup button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.popup button:hover {
    background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/Components/mergePopup.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,uCAAuC;IACvC,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;;AAGA;IACI,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* Popup.css */\n.popup {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 50%;\n    padding: 20px;\n    background: #fff;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);\n    z-index: 1000;\n}\n\n.popup_inner {\n    margin: 15px;\n}\n\n.popup h1 {\n    font-size: 20px;\n    margin-bottom: 20px;\n}\n\n.popup ul {\n    list-style: none;\n    padding: 0;\n}\n\n.popup ul li {\n    padding: 10px;\n    border: 1px solid #ddd;\n    margin-bottom: 10px;\n    cursor: pointer;\n    background-color: #f9f9f9;\n}\n\n.popup ul li:hover {\n    background-color: #efefef;\n}\n\n.popup ul li.selected {\n    background-color: #007bff;\n    color: white;\n}\n\n\n.popup button {\n    display: block;\n    margin: 20px auto 0;\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    cursor: pointer;\n}\n\n.popup button:hover {\n    background-color: #0056b3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
