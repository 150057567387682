// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* Allow the navbar to wrap on small screens */
    margin-bottom: 0;
    padding: 20px 0;
    background-color: #ffdd57;
}

.navbar button {
    margin: 0 10px;
    padding: 15px 30px;
    font-size: 2em;
    border: 2px solid;
    border-radius: 5px;
    background-color: #ffdd57;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
}

.navbar button:hover {
    background-color: #fff;
    color: #333;
    border-color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .navbar button {
        margin: 5px;
        /* Reduce margin */
        padding: 10px 20px;
        /* Reduce padding */
        font-size: 1em;
        /* Reduce font size for better fit */
    }

    .navbar {
        flex-direction: column;
        /* Stack buttons vertically */
        padding: 10px 0;
        /* Reduce navbar padding */
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,8CAA8C;IAC9C,gBAAgB;IAChB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,WAAW;IACX,kBAAkB;AACtB;;AAEA,2BAA2B;AAC3B;IACI;QACI,WAAW;QACX,kBAAkB;QAClB,kBAAkB;QAClB,mBAAmB;QACnB,cAAc;QACd,oCAAoC;IACxC;;IAEA;QACI,sBAAsB;QACtB,6BAA6B;QAC7B,eAAe;QACf,0BAA0B;IAC9B;AACJ","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    /* Allow the navbar to wrap on small screens */\n    margin-bottom: 0;\n    padding: 20px 0;\n    background-color: #ffdd57;\n}\n\n.navbar button {\n    margin: 0 10px;\n    padding: 15px 30px;\n    font-size: 2em;\n    border: 2px solid;\n    border-radius: 5px;\n    background-color: #ffdd57;\n    color: #333;\n    cursor: pointer;\n    transition: all 0.3s ease;\n    font-weight: bold;\n}\n\n.navbar button:hover {\n    background-color: #fff;\n    color: #333;\n    border-color: #333;\n}\n\n/* Responsive adjustments */\n@media (max-width: 768px) {\n    .navbar button {\n        margin: 5px;\n        /* Reduce margin */\n        padding: 10px 20px;\n        /* Reduce padding */\n        font-size: 1em;\n        /* Reduce font size for better fit */\n    }\n\n    .navbar {\n        flex-direction: column;\n        /* Stack buttons vertically */\n        padding: 10px 0;\n        /* Reduce navbar padding */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
