// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
    display: flex;
    flex-direction: row;
    /* Ensure that this is explicitly defined for larger screens */
    justify-content: space-between;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 20px;
    font-family: 'Amatic SC', cursive;
    font-weight: bold;
    font-size: 40px;
}

.left-side,
.right-side {
    width: 45%;
    /* Original width for larger screens */
}

.guac-image {
    width: 100%;
    height: auto;
    /* Ensure the image scales correctly */
}

.with-background {
    background-color: #ffdd57;
    /* Example background color */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column;
        /* Stack the image and text vertically */
        padding-top: 50px;
        /* Reduce the top padding */
        font-size: 24px;
        /* Adjust the font size for smaller screens */
    }

    .left-side,
    .right-side {
        width: 100%;
        /* Use full width for smaller devices */
    }

    /* Optionally, adjust the image size for smaller screens */
    .guac-image {
        max-width: 100%;
        /* Ensure the image is not too large */
        height: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/about.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8DAA8D;IAC9D,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;IACpB,iCAAiC;IACjC,iBAAiB;IACjB,eAAe;AACnB;;AAEA;;IAEI,UAAU;IACV,sCAAsC;AAC1C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA,+CAA+C;AAC/C;IACI;QACI,sBAAsB;QACtB,wCAAwC;QACxC,iBAAiB;QACjB,2BAA2B;QAC3B,eAAe;QACf,6CAA6C;IACjD;;IAEA;;QAEI,WAAW;QACX,uCAAuC;IAC3C;;IAEA,0DAA0D;IAC1D;QACI,eAAe;QACf,sCAAsC;QACtC,YAAY;IAChB;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');\n\n.about-container {\n    display: flex;\n    flex-direction: row;\n    /* Ensure that this is explicitly defined for larger screens */\n    justify-content: space-between;\n    align-items: center;\n    padding-top: 150px;\n    padding-bottom: 20px;\n    font-family: 'Amatic SC', cursive;\n    font-weight: bold;\n    font-size: 40px;\n}\n\n.left-side,\n.right-side {\n    width: 45%;\n    /* Original width for larger screens */\n}\n\n.guac-image {\n    width: 100%;\n    height: auto;\n    /* Ensure the image scales correctly */\n}\n\n.with-background {\n    background-color: #ffdd57;\n    /* Example background color */\n}\n\n/* Responsive adjustments for smaller screens */\n@media (max-width: 768px) {\n    .about-container {\n        flex-direction: column;\n        /* Stack the image and text vertically */\n        padding-top: 50px;\n        /* Reduce the top padding */\n        font-size: 24px;\n        /* Adjust the font size for smaller screens */\n    }\n\n    .left-side,\n    .right-side {\n        width: 100%;\n        /* Use full width for smaller devices */\n    }\n\n    /* Optionally, adjust the image size for smaller screens */\n    .guac-image {\n        max-width: 100%;\n        /* Ensure the image is not too large */\n        height: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
