// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffdd57;
    /* Yellow background */
    padding: 20px;
}

.logo {
    width: 100px;
    /* Adjust based on your logo's size */
    margin-right: 20px;
}

.welcome-box {
    font-family: 'Permanent Marker', cursive;
    font-size: 1.8em;
    /* Adjust based on the font and your preference */
    border: 2px solid #333;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffdd57;
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/Components/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;AACtB;;AAIA;IACI,wCAAwC;IACxC,gBAAgB;IAChB,iDAAiD;IACjD,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #ffdd57;\n    /* Yellow background */\n    padding: 20px;\n}\n\n.logo {\n    width: 100px;\n    /* Adjust based on your logo's size */\n    margin-right: 20px;\n}\n\n@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');\n\n.welcome-box {\n    font-family: 'Permanent Marker', cursive;\n    font-size: 1.8em;\n    /* Adjust based on the font and your preference */\n    border: 2px solid #333;\n    padding: 10px;\n    border-radius: 5px;\n    background-color: #ffdd57;\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
