// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    background-color: #000;
    /* Black background color */
    color: #fff;
    /* White text color */
    padding: 20px;
    margin: 0;
    /* Add padding around the content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.back-to-top-button {
    background-color: #fff;
    /* White background color */
    color: #000;
    /* Black text color */
    padding: 10px 20px;
    /* Add padding */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.logo-image {
    width: 100px;
    /* Adjust width as needed */
    height: auto;
    /* Maintain aspect ratio */
}`, "",{"version":3,"sources":["webpack://./src/Components/footer.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,SAAS;IACT,mCAAmC;IACnC,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,YAAY;IACZ,0BAA0B;AAC9B","sourcesContent":[".footer-container {\n    background-color: #000;\n    /* Black background color */\n    color: #fff;\n    /* White text color */\n    padding: 20px;\n    margin: 0;\n    /* Add padding around the content */\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n}\n\n.back-to-top-button {\n    background-color: #fff;\n    /* White background color */\n    color: #000;\n    /* Black text color */\n    padding: 10px 20px;\n    /* Add padding */\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.logo-image {\n    width: 100px;\n    /* Adjust width as needed */\n    height: auto;\n    /* Maintain aspect ratio */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
